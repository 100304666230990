import React from 'react';
import cn from 'classnames';
import styles from './Newsletter.module.sass';
import TextInput from '../TextInput';
import { FormattedMessage } from 'react-intl';

function Newsletter() {
  return (
    <div className={cn('section', styles.Newsletter)}>
      <div className={cn('center')}>
        <div className={styles.container}>
          <h2 className={cn('title title_mb-md', styles.title)}>
            <FormattedMessage id="Sign_up_to_our_newsletter" />
          </h2>
          <form className={styles.form} action="">
            <TextInput className={styles.field} type="text" placeholder="Your Email" />
            <div className={styles.buttons}>
              <button className={cn('button button-green button-wide', styles.button)} type="submit">
                <FormattedMessage id="Sign_Up" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
