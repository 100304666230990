import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import styles from './TextInput.module.sass';
import { Controller } from 'react-hook-form';

function TextInput({ className, label, ...props }) {
  const ref = useRef();
  useEffect(() => {
    if (props.message) ref.current.focus();
  }, [props.message]);

  return (
    <>
      {props?.control && props?.name ? (
        <Controller
          control={props?.control || undefined}
          name={props?.nameArray ? `${props?.nameArray}.${props?.index || 0}.${props?.name}` : props?.name}
          render={({ field }) => {
            // ref;onBlur; name
            const { onChange, value } = field;
            return (
              <div className={cn(className, styles.field)}>
                {label && <div className={styles.label}>{label}</div>}
                <div className={styles.wrap}>
                  <input
                    ref={props?.message ? ref : null}
                    className={cn(styles.input, Object.keys(props?.error)?.includes(props?.name) ? 'border !border-[red]' : '')}
                    {...props}
                    onChange={onChange}
                    placeholder={props?.placeholder || ''}
                    value={props?.value ? props?.value : value}
                  />
                  {props?.message && <p className={cn('text-[red]', 'text-[12px]')}>{props?.message}</p>}
                </div>
              </div>
            );
          }}
        />
      ) : (
        <div className={cn(className, styles.field)}>
          {label && <div className={styles.label}>{label}</div>}
          <div className={styles.wrap}>
            <input className={cn(styles.input, props?.error ? 'border !border-[red]' : '', '!focus:border-gray')} {...props} />
            {props?.message && <p className={cn('text-[red]', 'text-[12px]')}>{props?.message}</p>}
          </div>
        </div>
      )}
    </>
  );
}

export default TextInput;
