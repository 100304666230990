import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ConfigProvider } from './contexts/ConfigContext';
import Locales from './components/Locales';

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider>
      <Locales>
        <App />
      </Locales>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
