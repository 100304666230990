import React from 'react';
import cn from 'classnames';
import styles from './OurPartners.module.sass';
import { FormattedMessage } from 'react-intl';

function OurPartners() {
  return (
    <div className={cn('section', styles.about)}>
      <div className={cn('center', styles.center)}>
        {/* <div className={cn("stage")}>- Why Us</div> */}
        <h2 className={cn('title title_mb-lg', styles.title)}>
          <FormattedMessage id="Our_partners" />
        </h2>
        <p className={cn('body title_mb-lg ', styles.subtitle)}>
          <FormattedMessage id="partner_description" />
        </p>
        <div className={styles.list}>
          <div className={styles.item}>
            <img className={styles.pic} src={'/images/home/fmc_thai.png'} alt={'เกษตรกรไทย ไร้พรมแดน'} />
            <h3 className={cn(styles.subtitle)}>เกษตรกรไทย</h3>
          </div>
          <div className={styles.item}>
            <img className={styles.pic} src={'/images/home/fmc_laos.png'} alt={'ກະສິກອນລາວ ໄຣ້ຂອບເຂດ'} />
            <h3 className={cn(styles.subtitle)}>ກະສິກອນລາວ</h3>
          </div>

          <div className={styles.item}>
            <img className={styles.pic} src={'/images/home/fmc_viet.png'} alt={'Nông dân Việt Nam không biên giới'} />
            <h3 className={cn(styles.subtitle)}>Nông dân Việt Nam</h3>
          </div>

          <div className={styles.item}>
            <img className={styles.pic} src={'/images/home/fmc_cam.png'} alt={'កសិករកម្ពុជា គ្មានព្រំដែន'} />
            <h3 className={cn(styles.subtitle)}>កសិករកម្ពុជា</h3>
          </div>

          <div className={styles.item}>
            <img className={styles.pic} src={'/images/home/fmc_myanma.png'} alt={'မြန်မာလယ်သမား နယ်စည်းမရှိသော'} />
            <h3 className={cn(styles.subtitle)}>မြန်မာလယ်သမား</h3>
          </div>

          <div className={styles.item}>
            <img className={styles.pic} src={'/images/home/fmc_india.png'} alt={'सीमाहीन भारतीय किसान'} />
            <h3 className={cn(styles.subtitle)}>भारतीय किसान</h3>
          </div>

          <div className={styles.item}>
            <img className={styles.pic} src={'/images/home/fmc_indo.png'} alt={'Petani Indonesia Tanpa Batas'} />
            <h3 className={cn(styles.subtitle)}>Petani Indonesia</h3>
          </div>

          <div className={styles.item}>
            <img className={styles.pic} src={'/images/home/fmc_malay.png'} alt={'Petani Malaysia Tanpa Sempadan'} />
            <h3 className={cn(styles.subtitle)}>Petani Malaysia</h3>
          </div>

          <div className={styles.item}>
            <img className={styles.pic} src={'/images/home/fmc_ph.png'} alt={'Boundless Filipino Farmers'} />
            <h3 className={cn(styles.subtitle)}>Filipino Farmers</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurPartners;
