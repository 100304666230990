import axiosServices from '../../../utils/axios';

export const fetchCountryData1 = async () => {
  const res = await fetch('https://restcountries.com/v3.1/all');
  return res.json();
};

export const fetchCountryData = async () => {
  const res = await fetch('https://countriesnow.space/api/v0.1/countries/capital');
  return res.json();
};

export const fetchProviceData = async (country) => {
  const res = await fetch('https://countriesnow.space/api/v0.1/countries/states', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      country: country || 'Thailand'
    })
  });
  return res.json();
};

export const fetchCityData = async (country, city) => {
  const res = await fetch('https://countriesnow.space/api/v0.1/countries/cities', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      country: country || 'Thailand',
      state: city || 'Bangkok'
    })
  });
  return res.json();
};

export const createUser = (arg) =>
  new Promise((resolve, reject) => {
    // const formData = new FormData();
    // Object.keys(arg)?.forEach((key) => {
    //   if (typeof arg[key] === 'string') {
    //     formData.append(key, arg[key]);
    //   } else {
    //     formData.append(key, JSON.stringify(arg[key]));
    //   }
    // });

    axiosServices
      .post(`/user`, arg)
      .then((_response) => {
        resolve(_response.data);
      })
      .catch((_error) => {
        console.log(_error);
        reject(_error);
      });
  });

export const updateLoanUser = (arg) =>
  new Promise((resolve, reject) => {
    axiosServices
      .patch(`/user/update/loan`, arg)
      .then((_response) => {
        resolve(_response.data);
      })
      .catch((_error) => {
        console.log(_error);
        reject(_error);
      });
  });

export const updateDocumentUser = (arg) =>
  new Promise((resolve, reject) => {
    axiosServices
      .patch(`/user/update/document`, arg)
      .then((_response) => {
        resolve(_response.data);
      })
      .catch((_error) => {
        console.log(_error);
        reject(_error);
      });
  });

export const updateArea = (arg) =>
  new Promise((resolve, reject) => {
    axiosServices
      .patch(`/user/update/area`, arg)
      .then((_response) => {
        resolve(_response.data);
      })
      .catch((_error) => {
        console.log(_error);
        reject(_error);
      });
  });
