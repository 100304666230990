import React from 'react';
import cn from 'classnames';
import styles from './LegalPage.module.sass';
import Breadcrumbs from '../../components/Breadcrumbs';
import DropdownBox from '../../components/DropdownBox';
import { FormattedMessage } from 'react-intl';

const breadcrumbs = [
  {
    title: <FormattedMessage id="Home_page" />,
    url: '/'
  },
  {
    title: <FormattedMessage id="Categories" />,
    url: '/Category'
  },
  {
    title: <FormattedMessage id="terms_and_conditions" />
  }
];

const items = [
  {
    category: <FormattedMessage id="terms_conditions_category1" />,
    items: [
      {
        title: <FormattedMessage id="terms_conditions_title1" />,
        text: <FormattedMessage id="terms_conditions_text1" />
      },
      {
        title: <FormattedMessage id="terms_conditions_title1_2" />,
        text: <FormattedMessage id="terms_conditions_text1_2" />
      },
      {
        title: <FormattedMessage id="terms_conditions_title1_3" />,
        text: <FormattedMessage id="terms_conditions_text1_3" />
      },
      {
        title: <FormattedMessage id="terms_conditions_title1_4" />,
        text: <FormattedMessage id="terms_conditions_text1_4" />
      }
    ]
  },
  {
    category: <FormattedMessage id="terms_conditions_category2" />,
    items: [
      {
        title: <FormattedMessage id="terms_conditions_title2" />,
        text: <FormattedMessage id="terms_conditions_text2" />
      },
      {
        title: <FormattedMessage id="terms_conditions_title2_2" />,
        text: <FormattedMessage id="terms_conditions_text2_2" />
      }
    ]
  },
  {
    category: <FormattedMessage id="terms_conditions_category3" />,
    items: [
      {
        title: <FormattedMessage id="terms_conditions_title3" />,
        text: <FormattedMessage id="terms_conditions_text3" />
      },
      {
        title: <FormattedMessage id="terms_conditions_title3_2" />,
        text: <FormattedMessage id="terms_conditions_text3_2" />
      },
      {
        title: <FormattedMessage id="terms_conditions_title3_3" />,
        text: <FormattedMessage id="terms_conditions_text3_3" />
      }
    ]
  },
  {
    category: <FormattedMessage id="terms_conditions_category4" />,
    items: [
      {
        title: <FormattedMessage id="terms_conditions_title4" />,
        text: <FormattedMessage id="terms_conditions_text4" />
      },
      {
        title: <FormattedMessage id="terms_conditions_title4_2" />,
        text: <FormattedMessage id="terms_conditions_text4_2" />
      }
    ]
  }
];

function LegalPage() {
  return (
    <>
      <Breadcrumbs value={breadcrumbs} />
      <div className={cn('section')}>
        <div className={cn('center')}>
          <div className={cn('stage')}>
            - <FormattedMessage id="Legal" />
          </div>
          <h2 className={cn('title title_mb-lg', styles.title)}>
            <FormattedMessage id="Hygge" /> - <FormattedMessage id="terms_and_conditions" />
          </h2>
          <div className={styles.container}>
            {items.map((x, i) => (
              <div className={styles.section} key={i}>
                <div className={styles.category}>{x.category}</div>
                <div className={styles.list}>
                  {x.items.map((a, c) => (
                    <DropdownBox className={styles.item} value={a} key={c} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default LegalPage;
