import React from 'react';
import cn from 'classnames';
import styles from './Checkbox.module.sass';
import { Controller } from 'react-hook-form';

function Checkbox({ className, label, href, onChange, value, ...props }) {
  return (
    <>
      {props.control && props.name ? (
        <Controller
          control={props?.control || undefined}
          name={props?.name}
          render={({ field: { onChange: onChangeData, onBlur, value, ref } }) => {
            return (
              <div>
                <label className={cn(className, styles.checkbox)}>
                  <input className={styles.input} {...props} checked={value} onChange={onChange || onChangeData} />
                  <span className={styles.in}>
                    <span className={styles.tick}></span>
                    <span className={styles.text}>
                      {label}
                      {href ? <a href={href.link}>{href.label}</a> : null}
                    </span>
                  </span>
                </label>
                {props?.message && <p className={cn('text-[red]', 'text-[12px]')}>{props?.message}</p>}
              </div>
            );
          }}
        />
      ) : (
        <label className={cn(className, styles.checkbox)}>
          <input className={styles.input} {...props} checked={value} onChange={onChange} />
          <span className={styles.in}>
            <span className={styles.tick}></span>
            <span className={styles.text}>
              {label}
              {href ? <a href={href.link}>{href.label}</a> : null}
            </span>
          </span>
        </label>
      )}
    </>
  );
}

export default Checkbox;
