import React from 'react';
// import { Link } from 'react-router-dom';
import styles from './SignUp.module.sass';
import cn from 'classnames';
// import Breadcrumbs from '../../components/Breadcrumbs';
import TextInput from '../../components/TextInput';
import Checkbox from '../../components/Checkbox';
import useRegister from './hooks/useRegister';
import DropdownSelectOptions from '../../components/DropdownSelectOptions';
import DropdownSearch from '../../components/DropdownSearch';
import TextFile from '../../components/TextFile';
import { FormattedMessage } from 'react-intl';

const styleText = 'text-[22px] font-bold mb-12 text-center border-b-[4px] border-b-[#F6F7FB] border-solid border-[0px_0px_1px_0px]';
// eslint-disable-next-line no-unused-vars
const breadcrumbs = [
  {
    title: 'Home Page',
    url: '/'
  },
  {
    title: 'Sign Up'
  }
];

function SignUp() {
  const ctrl = useRegister();

  const form = (item, form, index, nameArray, value) => {
    if (item.type === 'text') {
      // console.log(form.formState.errors?.[nameArray]?.[index || 0]);

      return (
        <>
          <TextInput
            key={item.name}
            className={styles.field}
            name={item.name}
            label={item.label}
            type="text"
            control={form.control}
            error={
              nameArray && form.formState.errors?.[nameArray]?.[index || 0]
                ? form.formState.errors?.[nameArray]?.[index || 0]
                : form.formState.errors
            }
            message={
              nameArray && form.formState.errors?.[nameArray]?.[index || 0]?.[item.name]?.message
                ? form.formState.errors?.[nameArray]?.[index || 0]?.[item.name]?.message
                : form.formState.errors?.[item.name]?.message
            }
            index={index || null}
            nameArray={nameArray || null}
            value={value || null}
          />
        </>
      );
    } else if (item.type === 'date') {
      return (
        <>
          <TextInput
            key={item.name}
            className={styles.field}
            name={item.name}
            label={item.label}
            type="date"
            control={form.control}
            error={form.formState.errors}
            message={form.formState.errors?.[item.name]?.message}
            index={index || null}
            nameArray={nameArray || null}
            value={value || null}
          />
        </>
      );
    } else if (item.type === 'search') {
      return (
        <>
          <DropdownSearch
            name={item.name}
            label={item.label}
            // value={''}
            // setValue={() => {}}
            options={item.options}
            control={form.control}
            error={form.formState.errors?.[item.name]}
            message={form.formState.errors?.[item.name]?.message}
          />
        </>
      );
    } else if (item.type === 'checkbox') {
      return (
        <Checkbox
          name={item.name}
          className={cn(styles.checkbox, 'mt-10')}
          label={item.label}
          type="checkbox"
          value={form?.watch(item.name)}
          // onChange={() => form?.setValue(item.name, form?.watch(item.name))}
          control={form.control}
          error={form.formState.errors?.[item.name]}
          message={form.formState.errors?.[item.name]?.message}
        />
      );
    } else if (item.type === 'file') {
      return (
        <TextFile
          label={item.label}
          name={item.name}
          control={form?.control}
          image={form?.watch(item.name)}
          setImage={form?.setValue}
          error={
            nameArray && form.formState.errors?.[nameArray]?.[index || 0]
              ? form.formState.errors?.[nameArray]?.[index || 0]
              : form.formState.errors
          }
          message={
            nameArray && form.formState.errors?.[nameArray]?.[index || 0]?.[item.name]?.message
              ? form.formState.errors?.[nameArray]?.[index || 0]?.[item.name]?.message
              : form.formState.errors?.[item.name]?.message
          }
          index={index || null}
          nameArray={nameArray || null}
          value={value || null}
        />
      );
    }
    return (
      <>
        <DropdownSelectOptions
          name={item.name}
          control={form?.control}
          label={item.label}
          options={item?.options}
          error={form?.formState?.errors?.[item.name]}
          message={form?.formState?.errors?.[item.name]?.message}
        />
      </>
    );
  };

  return (
    <div>
      {/* <Breadcrumbs value={breadcrumbs} /> */}
      <div className={cn('section')}>
        <div className={styles.details}>
          <div className={cn('center')}>
            {/* <div className={cn("stage")}>- Sign Up</div> */}

            <form className={styles.form}>
              <div>
                {/* <div className={cn('flex items-center gap-1 justify-center w-full mb-20')}>
                  {ctrl.fields.map((ele) => {
                    return (
                      <div key={ele.tab} className={cn('flex items-center')}>
                        <p
                          className={cn(
                            'w-[48px] h-[48px] rounded-full flex items-center justify-center font-bold text-[20px]',
                            ele.tab <= ctrl.tab ? 'bg-green text-white ' : 'border border-gray border-solid text-black bg-white'
                          )}
                        >
                          {ele.tab + 1}
                        </p>
                        {ctrl.fields?.length - 1 !== ele.tab && (
                          <div className={cn('w-[128px] h-[2px] ml-1', ele.tab <= ctrl.tab ? 'bg-green' : 'bg-gray')} />
                        )}
                      </div>
                    );
                  })}
                </div> */}
                <div className={styles.wrap}>
                  <div className={cn(styles.status, styles.success)}></div>

                  {ctrl.tab === 0 && (
                    <>
                      <h2
                        className={cn(
                          // 'title ',
                          styleText
                        )}
                      >
                        General Information
                      </h2>
                      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-8 ">
                        {ctrl.fields[0].form.map((item) => {
                          return form(item, ctrl.form);
                        })}
                      </div>
                      {/* Loan Inquiry */}
                      <div className="grid md:grid-cols-1 gap-x-8 mb-5">
                        <Checkbox
                          control={ctrl.form?.control}
                          name={'loan_inquiry'}
                          className={cn(styles.checkbox, 'mt-10')}
                          label={'Funding Request'}
                          type="checkbox"
                          value={ctrl.form?.watch('loan_inquiry')}
                          error={ctrl?.form?.formState?.errors}
                          message={ctrl?.form?.formState?.errors?.loan_inquiry?.message}
                        />
                      </div>
                    </>
                  )}

                  {ctrl.tab === 1 && (
                    <>
                      {ctrl.form?.watch('loan_inquiry') && (
                        <>
                          <h2
                            className={cn(
                              // 'title title_mb-lg',
                              styleText
                            )}
                          >
                            Loan
                          </h2>

                          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-8 mb-5">
                            {ctrl?.fieldLoan?.form?.map((item) => {
                              return form(item, ctrl.formLoan);
                            })}
                          </div>
                          <div className="grid md:grid-cols-2  gap-x-8">
                            {ctrl?.fieldLoan?.address?.map((item) => {
                              return form(item, ctrl.formLoan);
                            })}
                          </div>
                          <div className={cn('mt-10')}>
                            <Checkbox
                              control={ctrl.formLoan?.control}
                              name={'carbon'}
                              className={styles.checkbox}
                              label={<FormattedMessage id="Do_farmers_want_to_survey" />}
                              type="checkbox"
                              value={ctrl.form?.watch('carbon')}
                              error={ctrl?.form?.formState?.errors}
                              message={ctrl?.form?.formState?.errors?.carbon?.message}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {ctrl.tab === 2 && (
                    <>
                      <h2
                        className={cn(
                          // 'title title_mb-lg',
                          styleText,
                          'mt-10'
                        )}
                      >
                        Attach Document
                      </h2>
                      <div className="grid md:grid-cols-3  gap-x-8">
                        <div className="flex items-center justify-center">
                          {ctrl.formImage.watch('passport') ? (
                            <img src="/images/passport.jpg" alt="passport" width={300} height={200} />
                          ) : (
                            <img src="/images/thai-id-card.avif" alt="thai-id-card" width={300} height={300} />
                          )}
                        </div>
                        <div className="col-span-2">
                          {[
                            { label: 'ID Card', value: 'id_card' },
                            { label: 'Passport', value: 'passport' }
                          ].map((item) => (
                            <Checkbox
                              key={item.value}
                              control={ctrl.formImage?.control}
                              name={item.value}
                              className={styles.checkbox}
                              label={item.label}
                              type="checkbox"
                              value={ctrl.formImage?.watch(item.value)}
                              onChange={() => {
                                if (item.value === 'passport') {
                                  ctrl.formImage.setValue('passport', true);
                                  ctrl.formImage.setValue('id_card', false);
                                } else {
                                  ctrl.formImage.setValue('passport', false);
                                  ctrl.formImage.setValue('id_card', true);
                                }
                              }}
                              error={ctrl?.formImage?.formState?.errors}
                              message={ctrl?.formImage?.formState?.errors?.[item.value]?.message}
                            />
                          ))}

                          <TextFile
                            label={ctrl.formImage?.watch('passport') ? 'Passport' : 'Id card'}
                            name={'image_card'}
                            control={ctrl.formImage?.control}
                            image={ctrl.formImage?.watch('image_card')}
                            setImage={ctrl.formImage?.setValue}
                            error={ctrl?.formImage?.formState?.errors}
                            message={ctrl?.formImage?.formState?.errors?.['image_card']?.message}
                          />
                        </div>
                        {/*  */}
                        <div className="col-span-3 border-b-[4px] border-b-[#F6F7FB] border-solid border-[0px_0px_1px_0px] my-3"></div>
                        <div className="flex items-center justify-center">
                          <img src="/images/hourse_re.jpeg" alt="thai-id-card" width={300} height={300} />
                        </div>
                        <div className="col-span-2">
                          <TextFile
                            label={'Hourse registration'}
                            name={'hourse_registration'}
                            control={ctrl.formImage?.control}
                            image={ctrl.formImage?.watch('hourse_registration')}
                            setImage={ctrl.formImage?.setValue}
                            error={ctrl?.formImage?.formState?.errors}
                            message={ctrl?.formImage?.formState?.errors?.['hourse_registration']?.message}
                          />
                        </div>
                        {/*  */}
                      </div>
                    </>
                  )}

                  {ctrl.tab === 3 && (
                    <>
                      <div
                        onClick={() =>
                          ctrl.formArray?.append({
                            address: '',
                            moo: '',
                            district: '',
                            sub_district: '',
                            province: '',
                            image: null,
                            type: '',
                            lat: '',
                            lng: '',
                            size: ''
                          })
                        }
                        className=" cursor-pointer flex items-center gap-1 justify-end w-full mb-2"
                      >
                        Add Area
                        <img src="/images/icon/add.svg" alt="add" width={30} height={30} />
                      </div>
                      {/* ctrl.formArray.fields */}
                      {ctrl.formArray.fields?.map((item, index) => {
                        return (
                          <div key={item.label} className="bg-green/20 p-5 mb-5">
                            <div className="grid md:grid-cols-3  gap-x-8">
                              {/* name: `area.${index}.${ele.name}` */}
                              {ctrl.fieldArea.form?.map((ele) => {
                                return form({ ...ele }, ctrl.formArea, index, 'area', item[ele.name]);
                              })}
                            </div>
                            <div
                              onClick={() => {
                                ctrl.formArray.remove(index);
                              }}
                              className=" cursor-pointer flex items-center gap-1 justify-end w-full"
                            >
                              Delete
                              <img src="/images/icon/trash.svg" alt="trash" width={30} height={30} />
                            </div>
                            <div className="col-span-3 border-b-[4px] border-b-[#F6F7FB] border-solid border-[0px_0px_1px_0px] my-5"></div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>

              {/* SAVE */}
              {/* styles.row */}
              <div className={cn('', 'flex', 'justify-between w-full')}>
                <Checkbox
                  control={ctrl.form?.control}
                  name={'condition'}
                  className={styles.checkbox}
                  label={<FormattedMessage id="terms_conditions2" />}
                  href={{ link: '/legal-page', label: <FormattedMessage id="terms_conditions" /> }}
                  type="checkbox"
                  value={ctrl.agree}
                  // onChange={() => ctrl.setAgree(!ctrl.agree)}
                  message={ctrl?.form?.formState?.errors?.condition?.message}
                />

                <div className={cn(styles.col, 'flex items-center justify-end')}>
                  <button
                    type={'submit'}
                    className={cn(
                      'button button-green max-w-[100px] mr-2 gap-1',
                      styles.button
                      // ctrl?.tabAll === ctrl.tab ? '' : '!hidden'
                    )}
                    onClick={
                      ctrl.tab === 0
                        ? ctrl.form?.handleSubmit(ctrl.onSubmit)
                        : ctrl.tab === 1
                          ? ctrl.formLoan?.handleSubmit(ctrl.onSubmitLoan)
                          : ctrl.tab === 2
                            ? ctrl.formImage?.handleSubmit(ctrl.onSubmitImage)
                            : ctrl.formArea?.handleSubmit(ctrl.onSubmitArea)
                    }
                  >
                    {/* <FormattedMessage id="Create_account" /> */}
                    submit
                  </button>
                  {ctrl.tab > 0 && (
                    <div
                      className={styles.col}
                      onClick={() => {
                        ctrl.setTab(ctrl.tab - 1);
                      }}
                    >
                      <div className={cn('button button-border ', styles.button)}>
                        <FormattedMessage id="Prev" />
                      </div>
                    </div>
                  )}
                  {ctrl.form?.watch('loan_inquiry') && ctrl.tab > 0 && (
                    <div
                      // type={'button'} button-wide
                      className={cn('button button-green ', styles.button, ctrl?.fields?.length - 1 === ctrl.tab ? '' : '!hidden')}
                      onClick={() => {
                        // if (ctrl.tab === 0) {
                        // const field = ['prefix', 'firstname', 'lastname', 'birthday', 'phone', 'email'];
                        // const has = [];
                        // field.forEach((key, index) => {
                        //   const watch = ctrl.form?.watch(key);
                        //   if (!watch) {
                        //     ctrl.form?.setError(key, {
                        //       ...ctrl?.form?.formState?.errors[key],
                        //       shouldFocus: true,
                        //       message: `${key} required`
                        //     });
                        //     has.push(index);
                        //   }
                        // });
                        // if (has.length < 1) {
                        // ctrl.setTab(ctrl.tab + 1);
                        // }
                        // } else {
                        ctrl.setTab(ctrl.tab + 1);
                        // }
                      }}
                    >
                      <FormattedMessage id="Next" />
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
