import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import $ from 'jquery';
import 'slick-slider';
import styles from './Review.module.sass';
import { FormattedMessage } from 'react-intl';

let items = [
  {
    img: '/images/content/ava-1.jpg',
    title: <FormattedMessage id="Private_Sale" />,
    content: <FormattedMessage id="review_description1" />
  },
  {
    img: '/images/content/ava-1.jpg',
    title: <FormattedMessage id="Private_Sale" />,
    content: <FormattedMessage id="review_description2" />
  },
  {
    img: '/images/content/ava-1.jpg',
    title: <FormattedMessage id="Private_Sale" />,
    content: <FormattedMessage id="review_description1" />
  }
];

function Review({ stage }) {
  const slider = useRef();

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    speed: 600,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          adaptiveHeight: true
        }
      }
    ]
  };

  useEffect(() => {
    const sliderReview = slider.current;
    $(sliderReview).slick(settings);
    return () => $(sliderReview).slick('unslick');
  });

  return (
    <div className={cn('section', styles.review)}>
      <div className={cn('center ', styles.center)}>
        <h2 className={cn('title title_mb-lg', styles.texttitle)}>
          <FormattedMessage id="Tokenomics" />
        </h2>
        <div className={styles.container}>
          <div className={styles.box}>
            {/* <div className={cn("stage")}>{stage}</div> */}
            {/* <h2 className={cn("title", styles.title)}>
              What our Customers are Saying
            </h2> */}
          </div>
          <div className={styles.wrap}>
            <div ref={slider} className={cn('slider-review', styles.slider)}>
              {items.map((x, i) => (
                <div className={styles.item} key={i}>
                  {/* <div className={styles.ava}>
                    <img className={styles.pic} src={x.img} alt="" />
                  </div> */}
                  <div className={styles.author}>{x.title}</div>
                  <div className={styles.text}>{x.content}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Review;
