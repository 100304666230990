import React from 'react';
import cn from 'classnames';
import styles from './About.module.sass';
import Image from '../../../components/Image';
import { FormattedMessage } from 'react-intl';

function About() {
  const roadmapData = [
    {
      title: <FormattedMessage id="stablecoin" />,
      content: <FormattedMessage id="Stablecoin-backed" />,
      icon: '/images/home/bank.svg'
    },
    {
      title: <FormattedMessage id="Blockchain" />,
      content: <FormattedMessage id="Blockchain-based" />,
      icon: '/images/home/language.svg'
    },
    {
      title: <FormattedMessage id="Decentralized" />,
      content: <FormattedMessage id="Decentralized-marketplace" />,
      icon: '/images/home/wifi-icon.svg'
    },
    {
      title: <FormattedMessage id="Integration" />,
      content: <FormattedMessage id="Integration_of_smart" />,
      icon: '/images/home/settings-icon.svg'
    }
  ];
  return (
    <div className={cn('section', styles.about)}>
      <div className={cn('center', styles.center)}>
        {/* <div className={cn("stage")}>- Why Us</div> */}
        <h2 className={cn('title title_mb-lg', styles.title)}>
          <FormattedMessage id="about_FMC" />
        </h2>
        <p className={cn('body title_mb-lg ', styles.subtitle)}>
          <FormattedMessage id="about_description" />
        </p>
        <div className={styles.list}>
          {roadmapData.map((item, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.icon}>
                <Image className="" src={item.icon} srcDark={item.icon} />
              </div>
              <div className={styles.category}>{item.title}</div>
              <div className={styles.text}>{item.content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default About;
