import React from 'react';
import cn from 'classnames';
import styles from './FMCWorks.module.sass';
import { FormattedMessage } from 'react-intl';

function FMCWorks() {
  const steps = [
    {
      title: <FormattedMessage id="Fiat_to_token_conversion" />,
      description: <FormattedMessage id="how_to_description1" />,
      imageUrl: '/images/home/hp_fiat.jpg', // replace with actual image path
      imageRight: false
    },
    {
      title: <FormattedMessage id="Marketplace_transactions" />,
      description: <FormattedMessage id="how_to_description2" />,
      imageUrl: '/images/home/hp_marketplace.jpg', // replace with actual image path
      imageRight: true
    },
    {
      title: <FormattedMessage id="Blockchain_transparency" />,
      description: <FormattedMessage id="how_to_description3" />,
      imageUrl: '/images/home/hp_blockchain.jpg', // replace with actual image path,
      imageRight: false
    },
    {
      title: <FormattedMessage id="how_to_title4" />,
      description: <FormattedMessage id="how_to_description4" />,
      imageUrl: '/images/home/hp_certifications.jpg', // replace with actual image path
      imageRight: true
    }
  ];
  return (
    <div className={cn('section', styles.about)}>
      <div className={cn('center', styles.center)}>
        {/* <div className={cn("stage")}>- Why Us</div> */}
        <h2 className={cn('title title_mb-lg', styles.title)}>
          <FormattedMessage id="How_FMC_works" />
        </h2>

        <div className={styles.steps_container}>
          {steps.map((step, index) => (
            <div key={index}>
              {step.imageRight ? (
                <div className={styles.step_card}>
                  <div className={styles.step_content}>
                    <h3 className={styles.step_content_title}>{step.title}</h3>
                    <p className={styles.step_content_body}>{step.description}</p>
                  </div>
                  {/* <div className={styles.blurred_overlay}></div> */}
                  <img src={step.imageUrl} alt={step.title} className={styles.step_image} />
                </div>
              ) : (
                <div className={styles.step_card}>
                  <img src={step.imageUrl} alt={step.title} className={styles.step_image} />
                  {/* <div className={styles.blurred_overlay}></div> */}
                  <div className={styles.step_content_left}>
                    <h3 className={styles.step_content_title}>{step.title}</h3>
                    <p className={styles.step_content_body}>{step.description}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FMCWorks;
