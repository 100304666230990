import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './Footer.module.sass';
import SocialLinks from '../SocialLinks';
import Theme from '../Theme';
import Image from '../Image';
import { FormattedMessage } from 'react-intl';

const nav = [
  {
    category: <FormattedMessage id="Categories" />,
    menu: [
      {
        url: '',
        title: <FormattedMessage id="On_sale" />
      },
      {
        url: '',
        title: <FormattedMessage id="Featured" />
      }
      // {
      //   url: '/category',
      //   title: 'Masks'
      // },
      // {
      //   url: '/category',
      //   title: 'Eye Care'
      // },
      // {
      //   url: '/category',
      //   title: 'Moisturizers'
      // },
      // {
      //   url: '/category',
      //   title: 'Treatments'
      // },
      // {
      //   url: '/category',
      //   title: 'Night Care'
      // },
      // {
      //   url: '/category',
      //   title: 'Sun Care'
      // }
    ]
  },
  {
    category: <FormattedMessage id="Legal" />,
    menu: [
      {
        url: '',
        title: <FormattedMessage id="Terms_of_service" />
      },
      {
        url: '',
        title: <FormattedMessage id="Privacy_policy" />
      }
      // {
      //   url: '/legal-page',
      //   title: 'Returns Policy'
      // },
      // {
      //   url: '/legal-page',
      //   title: 'Shipping'
      // },
      // {
      //   url: '/legal-page',
      //   title: 'Data Protection'
      // }
    ]
  },
  {
    category: <FormattedMessage id="Company" />,
    menu: [
      {
        url: '',
        title: <FormattedMessage id="About" />
      },
      {
        url: '',
        title: <FormattedMessage id="Faq" />
      }
      // {
      //   url: '/contacts',
      //   title: 'Contact'
      // },
      // {
      //   url: '/careers-page',
      //   title: 'Careers'
      // },
      // {
      //   url: '/',
      //   title: 'Vision'
      // },
      // {
      //   url: '/',
      //   title: 'Culture'
      // }
    ]
  }
];

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={cn('center', styles.center)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.row_logo}>
              <Link className={styles.logo} to="/">
                <Image className={styles.logo_desktop} src="/images/logo.png" srcDark="/images/logo.png" />
                <Image className={styles.logo_mobile} src="/images/logo.png" srcDark="/images/logo.png" />
              </Link>
              <div className={styles.category}>ArgoVerse</div>
            </div>

            <div className={cn('max-w-[300px] md:max-w-full')}>Whitepaper | Terms of Service | Privacy Policy</div>
            <div className={styles.copyright}>© 2024 FMC. All Rights Reserved.</div>
            <SocialLinks position={'footer'} />

            <Theme />
          </div>

          {nav.map((x, i) => (
            <div className={styles.col} key={i}>
              <div className={styles.category}>{x.category}</div>
              <div className={styles.menu}>
                {x.menu.map((s, a) => (
                  <Link className={styles.link} to={s.url} key={a}>
                    {s.title}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
