import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './Header.module.sass';
// import Search from './Search';
import Menu from './Menu';
// import Icon from '../Icon';
import Image from '../Image';

import { basket } from '../../mocks/products';
import DropdownLanguage from '../DropdownLanguage';
import useConfig from '../../hooks/useConfig';

const localizationOptions = [
  { key: 'en', label: 'English (EN)' },
  { key: 'th', label: 'Thailand (TH)' },
  { key: 'ch', label: 'Chinese (CH)' },
  { key: 'zh', label: 'Chinese (ZH)' },
  { key: 'id', label: 'Indonesian (ID)' },
  { key: 'ms', label: 'Malay (MS)' },
  { key: 'fil', label: 'Filipino (FIL)' },
  { key: 'vi', label: 'Vietnamese (VI)' },
  { key: 'lo', label: 'Laos (LO)' },
  { key: 'my', label: 'Burmese (MY)' },
  { key: 'km', label: 'Khmer (KM)' }
];
const Header = () => {
  const [visibleNav, setVisibleNav] = useState(false);
  const [, setActiveCart] = useState(false);
  const [basketItems] = useState(basket);

  const handleClick = () => setVisibleNav(false);

  const { i18n, onChangeLocalization } = useConfig();

  const handleListItemClick = (lang) => {
    onChangeLocalization(lang);
    // setOpen(false);
  };

  // const [visible, setVisible] = useState(false);

  useEffect(() => {
    visibleNav ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll');
    basketItems.length ? setActiveCart(true) : setActiveCart(false);
  }, [visibleNav, basketItems]);

  return (
    <header className={styles.header}>
      <div className={cn('center', styles.container)}>
        {/* <Link className={cn('w-[38px] h-[38px] flex items-center', 'lg:hidden ml-auto')} to="/" onClick={handleClick}>
          <Image className={styles.logo_mobile} src="/images/logo.png" srcDark="/images/logo.png" />
        </Link> */}
        {/* <button className={cn(styles.burger, { [styles.active]: visibleNav })} onClick={() => setVisibleNav(!visibleNav)}></button> */}
        <div className={cn('w-full !flex items-center gap-2')}>
          <Link className={cn(styles.logo, 'w-[38px] h-[38px]')} to="/" onClick={handleClick}>
            <Image className={cn(styles.logo_desktop, 'w-full h-full')} src="/images/logo.png" srcDark="/images/logo.png" />
            {/* <Image className={styles.logo_mobile} src="/images/logo.png" srcDark="/images/logo.png" /> */}
          </Link>
          <Link to="/">
            <div className={cn('md:block hidden montserrat-alternates-extrabold text-[24px] text-white leading-[23px]')}>
              <p>ArgoVerse</p>
              {/* <p>coin</p> */}
            </div>
          </Link>
        </div>
        {/* <div className={styles.control}>
          <div className={cn(styles.item, styles.hidden)}>
            <DropdownLanguage value={i18n} setValue={handleListItemClick} options={localizationOptions} className={cn('')} language />
          </div>
        </div> */}
        <DropdownLanguage value={i18n} setValue={handleListItemClick} options={localizationOptions} className={cn('')} language />

        {/* <div className={cn(styles.mobile)}>
            <div className={cn({ [styles.open]: visible }, styles.drop)}>
              <div className={styles.label}>
                <div className={cn(styles.burger, { [styles.active]: visible })} onClick={() => setVisible(!visible)}></div>
              </div>
              <div className={cn(styles.body)}>
                <div className={cn(styles.item, styles.hidden, 'my-[10px]')}>
                  <DropdownLanguage value={i18n} setValue={handleListItemClick} options={localizationOptions} className={cn('')} language />
                </div>
              </div>
            </div>
          </div> */}
      </div>

      <Menu value={visibleNav} onChange={setVisibleNav} />
    </header>
  );
};

export default Header;
