import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import styles from './DropdownSelect.module.sass';
import { Controller } from 'react-hook-form';

function DropdownSelectOptions({ className, label, value, setValue, options, ...props }) {
  const [visible, setVisible] = useState(false);
  const dropdown = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdown.current && !dropdown.current.contains(event.target)) setVisible(false);
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClick = (value) => {
    setValue?.(value);
    setVisible(false);
  };
  const ref = useRef();
  useEffect(() => {
    if (props.message) ref.current.focus();
  }, [props.message]);
  return (
    <>
      {props?.control && props?.name ? (
        <Controller
          control={props?.control || undefined}
          name={props?.nameArray ? `${props?.nameArray}.${props?.index || 0}.${props?.name}` : props?.name}
          render={({ field: { onChange, onBlur, value: val, ref } }) => (
            <div className={cn(className, { [styles.open]: visible }, styles.drop, 'mb-[10px]')} ref={dropdown}>
              {label && <div className={styles.label}>{label}</div>}
              <div
                ref={props?.message ? ref : null}
                className={cn(styles.head, props?.error ? 'border !border-[red]' : '', '!focus:border-gray')}
                onClick={() => setVisible(!visible)}
              >
                {val?.label}
              </div>
              <div className={cn(styles.body, props?.error ? '!top-[calc(100%-20px)]' : '!top-[calc(100%-15px)]')}>
                {options.map((x, i) => (
                  <div
                    className={cn({ [styles.active]: x?.value === val?.value }, styles.option)}
                    onClick={() => {
                      handleClick(x);
                      onChange(x);
                    }}
                    key={i}
                  >
                    {x.label}
                  </div>
                ))}
              </div>
              {props?.message && <p className={cn('text-[red]', 'text-[12px]')}>{props?.message}</p>}
            </div>
          )}
        />
      ) : (
        <div className={cn(className, { [styles.open]: visible }, styles.drop)} ref={dropdown}>
          {label && <div className={styles.label}>{label}</div>}
          <div className={styles.head} onClick={() => setVisible(!visible)}>
            {value?.label}
          </div>
          <div className={styles.body}>
            {options.map((x, i) => (
              <div
                className={cn({ [styles.active]: x.value === value }, styles.option)}
                onClick={() => {
                  handleClick(x);
                }}
                key={i}
              >
                {x.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default DropdownSelectOptions;
