/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

function TextFile({ className, label, image, setImage, ...props }) {
  // const [image, setImage] = useState(null); // To store the selected image file
  const [preview, setPreview] = useState(image ? URL.createObjectURL(image) : null); // To store the image preview URL
  const ref = useRef();
  // Handle file input change
  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    if (file) {
      setImage(props?.nameArray ? `${props?.nameArray}.${props?.index || 0}.${props?.name}` : props?.name, file);
      setPreview(URL.createObjectURL(file)); // Create a preview URL for the image
    }
  };

  return (
    <div className="my-3">
      {/* Input for uploading an image */}
      <input ref={ref} className="hidden" type="file" accept="image/*" onChange={handleImageChange} />

      {/* Display image preview if an image is selected */}
      <label className="">
        <FormattedMessage id={label} />
      </label>
      <div
        onClick={() => {
          ref?.current?.click();
        }}
        className="mt-[10px] bg-gray/50 border-2 border-solid rounded-lg border-gray w-[200px] h-[200px] flex items-center justify-center flex-col"
      >
        {preview ? (
          <img src={preview} alt="Image Preview" style={{ width: '100%', marginTop: '' }} />
        ) : (
          <>
            <img className={cn('rounded-lg')} src="/images/upload.svg" alt="upload" />
            <p className={cn('text', 'text-[10px]')}>
              <FormattedMessage id="img_warning" />
            </p>
          </>
        )}
      </div>
      {props?.message && <p className={cn('text-[red]', 'text-[12px]')}>{props?.message}</p>}
    </div>
  );
}

export default TextFile;
