import React from 'react';
import cn from 'classnames';
import styles from './Tokenomics.module.sass';
// import Image from "../../../components/Image";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { FormattedMessage } from 'react-intl';

function Tokenomics() {
  const tokenomicsData = [
    {
      title: <FormattedMessage id="Total_supply" />,
      description: '100,000,000,000 FMC',
      imageUrl: '/images/home/hp_supply.jpg' // replace with actual image path
    },
    {
      title: <FormattedMessage id="Token_Utility" />,
      description: <FormattedMessage id="Tokenomics_description1" />,
      imageUrl: '/images/home/hp_utility.jpg' // replace with actual image path
    },
    {
      title: <FormattedMessage id="Private_sale" />,
      description: <FormattedMessage id="Tokenomics_description2" />,
      imageUrl: '/images/home/hp_sales.jpg' // replace with actual image path
    },
    {
      title: <FormattedMessage id="Distribution_plan" />,
      description: <FormattedMessage id="Tokenomics_description3" />,
      imageUrl: '/images/home/hp_distribution.jpg' // replace with actual image path
    }
  ];
  return (
    <div className={cn('section', styles.about)}>
      <div className={cn('center', styles.center)}>
        <h2 className={cn('title title_mb-lg', styles.title)}>
          <FormattedMessage id="Tokenomics" />
        </h2>

        <div className={styles.tokenomicsgrid}>
          {tokenomicsData.map((item, index) => (
            <div className={styles.tokenomicscard} key={index}>
              <img src={item.imageUrl} alt={item.title} className={styles.tokenomicsImage} />
              <div className="">
                <h3 className={styles.tokenomics_title}>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
        <h2 className={cn('title title_mb-lg', styles.title)}>{''}</h2>
        <div className={styles.buttons}>
          <Link className={cn('button button-green')} to="/">
            <FormattedMessage id="Learn_more" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Tokenomics;
