import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './Main.module.sass';
import { FormattedMessage } from 'react-intl';

function Main() {
  return (
    <div className={cn('section', styles.main)}>
      <div className={cn('center')}>
        <div className={styles.container}>
          <div className={styles.details}>
            {/* <div className={cn("stage", styles.stage)}>- Skincare Products</div> */}
            <h1 className={cn('title', styles.title)}>
              <FormattedMessage id="banner_header" />
            </h1>
            <h1 className={cn('title', styles.subtitle)}>
              <FormattedMessage id="banner_description" />
            </h1>
            <div className={styles.rowButton}>
              <Link className={cn('button button-green')} to="/sign-up">
                <FormattedMessage id="get_started" />
              </Link>
              <Link className={cn('button button-green')} to="/WhitePaper_AgroVerse_0.0.1.pdf" target="_blank">
                <FormattedMessage id="whitepaper" />
              </Link>
            </div>
          </div>
          <div className={styles.preview}>
            <img className={styles.pic} src="/images/home/hp_banner.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
