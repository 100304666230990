import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import {
  createUser,
  fetchCityData,
  fetchCountryData,
  fetchProviceData,
  updateArea,
  updateDocumentUser,
  updateLoanUser
} from '../services/getCountry';
import toast from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';

export const editSchema = Yup.object().shape({
  firstname: Yup.string().required(<FormattedMessage id="firstname_warning_ENG" />),
  lastname: Yup.string().required(<FormattedMessage id="lastname_warning_ENG" />),
  birthday: Yup.string().required('Birthday is required'),
  phone: Yup.string().required('Phone is required'),
  email: Yup.string().email('Pattern Email wrong').required('E-mail is required'),
  prefix: Yup.object().shape({
    value: Yup.string().required(<FormattedMessage id="prefix_warning" />)
  }),
  condition: Yup.boolean().oneOf([true], <FormattedMessage id="terms_and_conditions_warning" />)
});

export const loanSchema = Yup.object().shape({
  id_no: Yup.string()
    .required(<FormattedMessage id="id_no_warning1" />)
    .max(13, <FormattedMessage id="id_no_warning2" />),
  nationality: Yup.object().shape({
    value: Yup.string().required(<FormattedMessage id="nation_warning" />)
  }),
  prefix: Yup.object().shape({
    value: Yup.string().required(<FormattedMessage id="nation_warning" />)
  }),
  sex: Yup.object().shape({
    value: Yup.string().required(<FormattedMessage id="nation_warning" />)
  }),
  city: Yup.object().shape({
    value: Yup.string().required(<FormattedMessage id="nation_warning" />)
  }),
  country: Yup.object().shape({
    value: Yup.string().required(<FormattedMessage id="nation_warning" />)
  }),
  district: Yup.object().shape({
    value: Yup.string().required(<FormattedMessage id="nation_warning" />)
  }),
  sub_district: Yup.string().required(<FormattedMessage id="id_no_warning1" />),
  firstname: Yup.string().required('Firstname is required'),
  lastname: Yup.string().required('Lastname is required'),
  birthday: Yup.string().required('Birthday is required')
});

export const documentSchema = Yup.object().shape({
  id_card: Yup.boolean(),
  passport: Yup.boolean(),
  hourse_registration: Yup.mixed().required('Hourse registration is required'),
  image_card: Yup.mixed().required('Hourse registration is required')
});
const areaSchema = Yup.object().shape({
  area: Yup.array().of(
    Yup.object().shape({
      address: Yup.string().required('Address is required'),
      moo: Yup.string(),
      district: Yup.string().required('District is required'),
      sub_district: Yup.string().required('Sub district is required'),
      province: Yup.string().required('Province is required'),
      image: Yup.mixed().required('Image is required'),
      type: Yup.string().required('Type Area is required'),
      lat: Yup.string().required('Lattitude is required'),
      lng: Yup.string().required('Longtitude is required'),
      size: Yup.string().required('Size area is required')
    })
  )
});
export const sexOptions = [
  { label: <FormattedMessage id="Male" />, value: 'male' },
  { label: <FormattedMessage id="Female" />, value: 'female' }
];

export const prefixOptions = [
  { label: <FormattedMessage id="prefix1" />, value: 'Mr.' },
  { label: <FormattedMessage id="prefix2" />, value: 'Mrs.' },
  { label: <FormattedMessage id="prefix3" />, value: 'Miss.' }
];

export const nationalityOptions = [
  { label: <FormattedMessage id="Male" />, value: 'male' },
  { label: <FormattedMessage id="Female" />, value: 'female' }
];

const useRegister = () => {
  const tabAll = 3;
  const [tab, setTab] = useState(0);
  const [agree, setAgree] = useState(true);
  const [country, setCountry] = useState([]);
  const [provice, setProvice] = useState([]);
  const [districe, setDistrice] = useState([]);

  const form = useForm({
    resolver: yupResolver(editSchema),
    defaultValues: {
      prefix: { label: 'Mr.', value: 'Mr.' },
      phone: '',
      condition: true,
      loan_inquiry: true,
      firstname: '',
      lastname: '',
      email: '',
      birthday: ''
    }
  });

  const formLoan = useForm({
    resolver: yupResolver(loanSchema),
    defaultValues: {
      phone_register: form.watch('phone'),
      prefix: { label: 'Mr.', value: 'Mr.' },
      sex: { label: 'Male', value: 'male' },
      id_no: '',
      firstname: '',
      lastname: '',
      career: '',
      phone: '',
      birthday: '',
      business_name: '',
      business_registration_no: '',
      nationality: {
        label: 'Thailand',
        value: 'Thailand'
      },
      country: {
        label: 'Thailand',
        value: 'Thailand'
      },
      address: '',
      floor: '',
      village: '',
      road: '',
      city: '',
      district: '',
      postcode: ''
    }
  });

  const formImage = useForm({
    // resolver: yupResolver(editSchema),
    defaultValues: {
      phone_register: form.watch('phone'),
      image_card: null,
      hourse_registration: null
    }
  });

  const formArea = useForm({
    resolver: yupResolver(areaSchema),
    defaultValues: {
      phone_register: form.watch('phone'),
      area: [
        {
          address: '',
          moo: '',
          district: '',
          sub_district: '',
          province: '',
          image: null,
          type: '',
          lat: '',
          lng: '',
          size: ''
        }
      ]
    }
  });

  const formArray = useFieldArray({
    control: formArea.control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'area' // unique name for your Field Array
  });

  useEffect(() => {
    let load = false;
    if (!load) {
      fetchCountryData()
        .then((_res) => {
          const coun = _res?.data?.map((ele) => ({
            label: ele?.name,
            value: ele?.name,
            data: ele
          }));
          setCountry(coun);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      load = true;
    };
  }, []);

  useEffect(() => {
    let load = false;
    if (!load) {
      // console.log('country', form?.watch('country')?.value);
      // console.log(form?.watch('country')?.value, form?.watch('city')?.value);
      fetchProviceData(formLoan?.watch('country')?.value)
        .then((_res) => {
          const coun = _res?.data?.states?.map((ele) => ({
            label: ele?.name,
            value: ele?.name,
            data: ele
          }));
          setProvice(coun);
        })
        .catch((err) => {
          console.error(err);
        });

      fetchCityData(formLoan?.watch('country')?.value, formLoan?.watch('city')?.value)
        .then((_res) => {
          // console.log('_res', _res);
          const coun = _res?.data?.map((ele) => ({
            label: ele,
            value: ele,
            data: ele
          }));
          setDistrice(coun);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      load = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formLoan?.watch('country')?.value, formLoan?.watch('city')?.value]);

  //   const {
  //     formState: { errors }
  //   } = form;

  const fields = [
    {
      tab: 0,
      title: 'General Information',
      form: [
        { label: <FormattedMessage id="Prefix" />, name: 'prefix', type: 'select', options: prefixOptions },
        { label: <FormattedMessage id="Firstname" />, name: 'firstname', type: 'text' },
        { label: <FormattedMessage id="Lastname" />, name: 'lastname', type: 'text' },
        { label: <FormattedMessage id="Birthday" />, name: 'birthday', type: 'date' },

        { label: <FormattedMessage id="Phone" />, name: 'phone', type: 'text' },
        { label: <FormattedMessage id="E-mail" />, name: 'email', type: 'text' }
      ]
    }
  ];

  const fieldLoan = {
    form: [
      { label: <FormattedMessage id="id_no" />, name: 'id_no', type: 'text' },
      { label: <FormattedMessage id="Prefix" />, name: 'prefix', type: 'select', options: prefixOptions },
      { label: <FormattedMessage id="Firstname" />, name: 'firstname', type: 'text' },
      { label: <FormattedMessage id="Lastname" />, name: 'lastname', type: 'text' },
      { label: <FormattedMessage id="Birthday" />, name: 'birthday', type: 'date' },
      { label: <FormattedMessage id="Phone" />, name: 'phone', type: 'text' },
      { label: <FormattedMessage id="Sex" />, name: 'sex', type: 'select', options: sexOptions },
      { label: <FormattedMessage id="Nationality" />, name: 'nationality', type: 'search', options: country },
      { label: <FormattedMessage id="Career" />, name: 'career', type: 'text' },
      { label: <FormattedMessage id="Business_name" />, name: 'business_name', type: 'text' },
      { label: <FormattedMessage id="Business_registration_no" />, name: 'business_registration_no', type: 'text' }
    ],
    address: [
      { label: <FormattedMessage id="Address" />, name: 'address', type: 'text' },
      { label: <FormattedMessage id="Floor" />, name: 'floor', type: 'text' },
      { label: <FormattedMessage id="Village" />, name: 'village', type: 'text' },
      { label: <FormattedMessage id="Road" />, name: 'road', type: 'text' },

      { label: <FormattedMessage id="Country" />, name: 'country', type: 'search', options: country },
      { label: <FormattedMessage id="City/Provice" />, name: 'city', type: 'search', options: provice },
      { label: <FormattedMessage id="District" />, name: 'district', type: 'search', options: districe },
      { label: <FormattedMessage id="Sub District" />, name: 'sub_district', type: 'text' },
      { label: <FormattedMessage id="Postcode" />, name: 'postcode', type: 'text' }
    ]
  };

  const fieldArea = {
    form: [
      { label: 'Image Area', name: 'image', type: 'file' },
      { label: 'Address', name: 'address', type: 'text' },
      { label: 'Moo', name: 'moo', type: 'text' },
      { label: 'District', name: 'district', type: 'text' },
      { label: 'Sub District', name: 'sub_district', type: 'text' },
      { label: 'Province', name: 'province', type: 'text' },
      { label: 'Planting type', name: 'type', type: 'text' },
      { label: 'Lat', name: 'lat', type: 'text' },
      { label: 'Lng', name: 'lng', type: 'text' },
      { label: 'Size', name: 'size', type: 'text' }
    ]
  };
  // const fieldDocument = {
  //   form: [{ label: <FormattedMessage id="id_no" />, name: 'id_no', type: 'text' }]
  // };
  const submit = (data) => {
    const dt = {
      ...data
    };
    createUser(dt)
      .then((_res) => {
        toast.success(_res?.message || <FormattedMessage id="Register_success" />);
        // window.location.href = '/';
        if (_res?.data?.loan_inquiry) {
          setTab(1);
          form.setValue('loan_inquiry', _res?.data?.loan_inquiry);
          formLoan.setValue('phone_register', _res?.data?.phone);
        } else {
          window.location.href = '/';
        }
        //
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          err?.message?.includes('duplicate key error collection')
            ? 'This phone number Registered'
            : <FormattedMessage id="Image_to_large" /> || <FormattedMessage id="Register_error" />
        );
      });
  };

  function onSubmit({ ...data }) {
    // console.log('data', data);
    const dt = {
      ...data,
      // nationality: data.nationality.value,
      prefix: data.prefix.value
    };
    // console.log('dt', dt);
    submit(dt);
    // if (data?.carbon) {

    // } else {
    //   form?.setError('area', {
    //     shouldFocus: true,
    //     message: `Area required`
    //   });
    //   toast.error(<FormattedMessage id="Please_fill_area" />);
    // }
    // } else {
    //   submit(data);
    // }
  }
  // console.log('provice', provice);
  // console.log('dis', districe);

  function onSubmitLoan({ ...data }) {
    const dt = {
      ...data,
      nationality: data.nationality.value,
      prefix: data.prefix.value,
      sex: data.sex.value,
      city: data.city.value,
      country: data.country.value,
      district: data.district.value
    };
    updateLoanUser(dt)
      .then((_res) => {
        if (_res) {
          form.setValue('loan_inquiry', _res?.data?.loan_inquiry);
          formImage.setValue('phone_register', _res?.data?.phone);
          toast.success('Update loan data success');
          setTab(2);
        }
      })
      .catch((err) => {
        console.log('rr', err);
        toast.error('Update loan data error');
      });
  }
  function onSubmitImage({ ...data }) {
    // console.log('dt', data);
    if ((data.passport === false && data.id_card === false) || (data.passport === undefined && data.id_card === undefined)) {
      formImage?.setError('passport', {
        shouldFocus: true,
        message: `Please select at least one passport or identification card option`
      });
      return;
    }
    const formData = new FormData();
    formData.append('phone_register', data.phone_register);
    formData.append('image_card', data.image_card);
    formData.append('hourse_registration', data.hourse_registration);
    formData.append('passport', data.passport);
    formData.append('id_card', data.id_card);

    // phone_register: form.watch('phone'),
    // image_card: null,
    // hourse_registration: null
    updateDocumentUser(formData)
      .then((_res) => {
        if (_res) {
          form.setValue('loan_inquiry', _res?.data?.loan_inquiry);
          formArea.setValue('phone_register', _res?.data?.phone);
          toast.success('Update loan data success');
          setTab(3);
        }
      })
      .catch((err) => {
        console.log('rr', err);
        toast.error('Update loan data error');
      });
  }

  function onSubmitArea({ ...data }) {
    // console.log('data', data);
    const formData = new FormData();

    formData.append('phone_register', data.phone_register);
    formData.append('area', JSON.stringify(data.area));
    formData.append('image', JSON.stringify(data.area.map((el) => el.image)));
    // Loop through the images and append them to the FormData
    for (let i = 0; i < data.area.length; i++) {
      const files = data.area[i];
      formData.append('images', files.image, files.name);
    }
    updateArea(formData)
      .then((_res) => {
        if (_res) {
          toast.success('Update area data success');
          window.location.href = '/';
        }
      })
      .catch((err) => {
        console.log('rr', err);
        toast.error('Update area data error');
      });
  }

  return {
    form,
    fields,
    onSubmit,
    onSubmitLoan,
    onSubmitImage,
    onSubmitArea,
    tab,
    setTab,
    agree,
    setAgree,
    country,
    fieldLoan,
    formLoan,
    tabAll,
    formImage,
    formArea,
    formArray,
    fieldArea
  };
};

export default useRegister;
