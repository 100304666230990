import React from 'react';
import cn from 'classnames';
import styles from './Roadmap.module.sass';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Image from '../../../components/Image';
import { FormattedMessage } from 'react-intl';

function RoadMap() {
  const roadmapData = [
    {
      quarter: 'Q4 2024',
      title: <FormattedMessage id="roadmap_t1" />,
      icon: '/images/home/bank.svg'
    },
    {
      quarter: 'Q1 2025',
      title: <FormattedMessage id="roadmap_t2" />,
      icon: '/images/home/language.svg'
    },
    {
      quarter: 'Q3 2025',
      title: <FormattedMessage id="roadmap_t3" />,
      icon: '/images/home/wifi-icon.svg'
    },
    {
      quarter: 'Q4 2025',
      title: <FormattedMessage id="roadmap_t4" />,
      icon: '/images/home/settings-icon.svg'
    }
  ];
  return (
    <div className={cn('section', styles.about)}>
      <div className={cn('center', styles.center)}>
        {/* <div className={cn("stage")}>- Why Us</div> */}
        <h2 className={cn('title title_mb-lg', styles.title)}>
          <FormattedMessage id="RoadMap" />
        </h2>
        <h3 className={cn(styles.subtitle)}>
          <FormattedMessage id="Our_vision_and_roadmap" />
        </h3>
        <p className={cn('body title_mb-lg ', styles.content)}>
          <FormattedMessage id="roadmap_description" />
        </p>
        <div className={styles.list}>
          {roadmapData.map((item, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.icon}>
                <Image className="" src={item.icon} srcDark={item.icon} />
              </div>
              <div className={styles.category}>{item.quarter}</div>
              <div className={styles.text}>{item.title}</div>
            </div>
          ))}
        </div>
        <h2 className={cn('title title_mb-lg', styles.title)}>{''}</h2>
        <div className="">
          <Link className={cn('button button-green')} to="/sign-up">
            <FormattedMessage id="Join_Us" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default RoadMap;
