import React from 'react';
import Main from './Main';
// import Categories from "./Categories";
// import Products from "./Products";
import About from './About';
// import Review from "../../components/Review";
// import Blog from "./Blog";
import FMCWorks from './FMCWorks';
import OurPartners from './OurPartners';
import RoadMap from './Roadmap';
import Tokenomics from './Tokenomics';

function Home() {
  return (
    <>
      <Main />
      {/* <Categories /> */}
      {/* <Products /> */}
      <About />

      <FMCWorks />
      {/* <Review stage="- Our Reviews" /> */}
      {/* <Blog /> */}
      <Tokenomics />
      <RoadMap />
      <OurPartners />
    </>
  );
}

export default Home;
