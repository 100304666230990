import React, { useState, useEffect, useRef, useMemo } from 'react';
import cn from 'classnames';
import styles from './DropdownSelect.module.sass';
import { Controller } from 'react-hook-form';

function DropdownSearch({ className, label, value, setValue, options, ...props }) {
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState('');

  const dropdown = useRef();
  const inputRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdown.current && !dropdown.current.contains(event.target)) setVisible(false);
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClick = (value) => {
    setValue?.(value);
    setVisible(false);
  };

  const dataOptios = useMemo(() => {
    if (search === '') {
      return options;
    }
    return options?.filter((ele) => ele?.label?.toLowerCase()?.includes(search?.toLowerCase()));
  }, [search, options]);

  return (
    <>
      {props?.control && props?.name ? (
        <Controller
          control={props?.control || undefined}
          name={props?.nameArray ? `${props?.nameArray}.${props?.index || 0}.${props?.name}` : props?.name}
          render={({ field: { onChange, onBlur, value: val, ref } }) => (
            <div className={cn(className, { [styles.open]: visible }, styles.drop, '')} ref={dropdown}>
              {/* mb-[40px] */}
              {label && <div className={styles.label}>{label}</div>}
              <div
                className={cn(styles.head, props?.error ? 'border !border-[red]' : '', '!focus:border-gray')}
                onClick={(e) => {
                  e.preventDefault();
                  setVisible(!visible);
                  inputRef?.current?.focus();
                }}
              >
                {!visible && val?.label}
                <input
                  ref={inputRef}
                  className={cn('w-full h-full', visible ? '' : 'opacity-0')}
                  onClick={(e) => {
                    e.preventDefault();
                    setVisible(true);
                  }}
                  onChange={(event) => {
                    setSearch(event.target.value);
                  }}
                  defaultValue={val?.label}
                />
              </div>
              <div className={styles.body}>
                {dataOptios.map((x, i) => (
                  <div
                    className={cn({ [styles.active]: x?.value === val?.value }, styles.option)}
                    onClick={() => {
                      handleClick(x);
                      onChange(x);
                    }}
                    key={i}
                  >
                    {x?.label}
                  </div>
                ))}
              </div>
              {props?.message && <p className={cn('text-[red]', 'text-[12px]')}>{props?.message}</p>}
            </div>
          )}
        />
      ) : (
        <div className={cn(className, { [styles.open]: visible }, styles.drop)} ref={dropdown}>
          {label && <div className={styles.label}>{label}</div>}
          <div className={styles.head} onClick={() => setVisible(!visible)}>
            {value?.label}
          </div>
          <div className={styles.body}>
            {dataOptios.map((x, i) => (
              <div
                className={cn({ [styles.active]: x?.value === value?.value }, styles.option)}
                onClick={() => {
                  handleClick(x);
                }}
                key={i}
              >
                {x?.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default DropdownSearch;
