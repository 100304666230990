import PropTypes from 'prop-types';
import { createContext } from 'react';

// project-imports
import config from '../config';
import useLocalStorage from '../hooks/useLocalStorage';

// initial state
const initialState = {
  ...config,

  onChangeLocalization: () => {}
};

// console.log('initialState', initialState);

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext(initialState);

function ConfigProvider({ children }) {
  const [config, setConfig] = useLocalStorage('arg-verse-config', initialState);

  const onChangeLocalization = (lang) => {
    setConfig({
      ...config,
      i18n: lang
    });
  };

  return (
    <ConfigContext.Provider
      value={{
        ...config,
        onChangeLocalization
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

ConfigProvider.propTypes = {
  children: PropTypes.node
};

export { ConfigProvider, ConfigContext };
