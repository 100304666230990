import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// third-party
import { IntlProvider } from 'react-intl';

// project-imports
import useConfig from '../hooks/useConfig';

// load locales files
const loadLocaleData = (locale) => {
  switch (locale) {
    case 'th':
      return import('../utils/locales/th.json');
    case 'id':
      return import('../utils/locales/id.json');
    case 'ch':
      return import('../utils/locales/ch.json');
    case 'ms':
      return import('../utils/locales/ms.json');
    case 'fil':
      return import('../utils/locales/fil.json');
    case 'vi':
      return import('../utils/locales/vi.json');
    case 'lo':
      return import('../utils/locales/lo.json');
    case 'my':
      return import('../utils/locales/my.json');
    case 'km':
      return import('../utils/locales/km.json');
    case 'zh':
      return import('../utils/locales/zh.json');
    case 'en':
    default:
      return import('../utils/locales/en.json');
  }
};

// ==============================|| LOCALIZATION ||============================== //

const Locales = ({ children }) => {
  const { i18n } = useConfig();

  const [messages, setMessages] = useState();

  useEffect(() => {
    loadLocaleData(i18n).then((d) => {
      setMessages(d.default);
    });
  }, [i18n]);

  return (
    <>
      {messages && (
        <IntlProvider locale={i18n} defaultLocale="en" messages={messages}>
          {children}
        </IntlProvider>
      )}
    </>
  );
};

Locales.propTypes = {
  children: PropTypes.node
};

export default Locales;
